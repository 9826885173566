import {
  ClockIcon,
  LocationMarkerIcon,
  CalendarIcon as CalendarIconSolid,
  CreditCardIcon,
  ArrowNarrowLeftIcon,
} from '@heroicons/react/solid';
import { DatePicker } from '../DatePicker';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { GroupPlan, Credential, Group, CalendarType } from '@prisma/client';
import { classNames } from '../../utils';
import './appointment-widget.module.scss';
import dayjs, { Dayjs } from 'dayjs';
import Moment from 'react-moment';
import TimeSlotButton from '../TimeSlotButton';
import {
  AppointmentGuestInfos,
  GuestInfos,
  TimeSlotEntity,
} from '../AppointmentGuestInfos';
import {
  CalendarIcon,
  CheckIcon,
  GlobeAltIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import {
  GlobeIcon,
  VideoCameraIcon,
  PhoneOutgoingIcon,
  PhoneIncomingIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { ITimezone, TimeZone } from '../Timezone';
import { Menu, Transition } from '@headlessui/react';
import { AppointmentEntity } from '@yalendar/api-interfaces';
import { FormattedNumber, IntlProvider } from 'react-intl';
import 'dayjs/locale/fr';

export type WidgetCalendar = {
  id?: string;
  active: boolean;
  name: string;
  slug: string;
  description: string;
  durationInMinutes: number;
  price: number;
  currency: string;
  location: string;
  locations: any[];
  colorInHexadecimal: string;
  timezone?: string;
  disableGuests?: boolean;
  showHostAvatar?: boolean;
  imageUrl?: string;
  group: {
    id: string;
    name: string;
    slug: string;
    profilePictureUrl: string;
    plan: GroupPlan;
    forceBranding: boolean;
  };
  calendarAssignments: {
    userId: string;
    active: boolean;
    user?: {
      id: string;
      name: string;
      profilePictureUrl: string;
      credentials: { type: string }[] | undefined;
    };
  }[];
  customInputs: any[];
};

export type SlotInterface = {
  status: 'available' | 'unavailable';
  startTime: string;
  endTime: string;
  remainingSpot: number;
  conflicts: any[];
};

export type TimeSlotInterface = {
  dateOfDay: string;
  slots: SlotInterface[];
};

export interface BookAppointment {
  location: string;
  guestName: string;
  guestEmail: string;
  guestTimezone: string;
  startTime: string;
  endTime: string;
  additionalInfos?: any;
  rescheduleReason?: string;
  guests: string[];
}
/* eslint-disable-next-line */
export interface AppointmentWidgetProps {
  selectedTime?: TimeSlotEntity;
  onSelectedDateChanged?: (date: Dayjs) => void;
  onSelectedMonthChanged?: (monthFormat: Dayjs, month: number) => void;
  selectedDate?: Dayjs;
  initialPeriodStart: string;
  timeSlots?: TimeSlotInterface[];
  isLoadingSlots?: boolean;
  calendar: WidgetCalendar;
  getSlot?: (slot: { startTime: string; endTime: string }) => void;
  bookAppointment?: (
    calendarId: string,
    data: BookAppointment,
  ) => Promise<void>;
  appointmentToUpdate?: AppointmentEntity;
  onTimeSlotSelected: (slot: SlotInterface) => void;
  refreshSlots?: (month: number) => void;
  rescheduleAppointment?: (
    appointmentId: string,
    data: BookAppointment,
  ) => void;
  showRibbon?: boolean;
  guestTimezone: ITimezone;
  calendarTimezone?: string;
  changeGuestTimezone: (tz: ITimezone) => void;
  calendarLocations?: any[];
  calendarCustomInputs?: any[];
  hideLangageSelection?: boolean;
  isDiagnosticMode?: boolean;
  showRemainingSpot?: boolean;
  showTroubleShotButton?: boolean;
  onTroubleShotClicked?: (activate: boolean) => void;
  hideInfosColumn?: boolean;
  isEmbeded?: boolean;
  shouldHideCalendarImage?: boolean;
  isGoBack?: boolean;
  goBack?: () => void;
  onCancelFromGuestInfo?: () => void;
  previewMode?: boolean;
  onFileUpload?: (file: any) => any;
  isAllowedToCollectPayment?: boolean;
}
const GMT0 = 'Etc/GMT';

export function AppointmentWidget({
  selectedTime = undefined,
  onSelectedDateChanged,
  onSelectedMonthChanged,
  selectedDate,
  initialPeriodStart,
  calendar,
  timeSlots = [],
  isLoadingSlots,
  bookAppointment,
  appointmentToUpdate,
  rescheduleAppointment,
  showRibbon = true,
  changeGuestTimezone,
  onTimeSlotSelected,
  guestTimezone,
  calendarTimezone,
  calendarLocations = [],
  calendarCustomInputs = [],
  hideLangageSelection = false,
  isDiagnosticMode = false,
  showRemainingSpot = false,
  showTroubleShotButton = false,
  onTroubleShotClicked,
  hideInfosColumn = false,
  isEmbeded = false,
  shouldHideCalendarImage = false,
  onCancelFromGuestInfo = () => void 0,
  previewMode = false,
  onFileUpload,
  isAllowedToCollectPayment = false,
  isGoBack,
  goBack,
}: AppointmentWidgetProps) {
  const { t, i18n } = useTranslation();
  const [
    availableTimeslotsForSelectedDate,
    setAvailableTimeslotsForSelectedDate,
  ] = useState<TimeSlotInterface>({
    dateOfDay: '',
    slots: [],
  });

  const currentTime = useMemo(() => selectedTime, [selectedTime]);
  const [location, setLocation] = useState<string>(
    calendar.locations?.length === 1 ? calendar.locations[0].type : undefined,
  );
  const isDayDisabled = useCallback(
    (date: Dayjs): boolean => {
      const dayFilter = timeSlots?.find(
        (slot) => slot.dateOfDay === date.format('YYYY-MM-DD'),
      );
      if (!dayFilter) {
        return true;
      }
      return dayFilter.slots.length === 0;
    },
    [timeSlots],
  );

  const timeToDateWithTimezone = (
    date: Dayjs,
    time: string,
    isHost?: boolean,
  ) => {
    const dateWithTime = dayjs(
      `${date.format('YYYY-MM-DD')}-${time}`,
      'YYYY-MM-DD-HH:mm',
    );
    if (isHost) return dateToHostTimezone(dateWithTime.toDate());
    return dateWithTime;
  };

  const dateToHostTimezone = (date: Date) => {
    guestTimezone =
      typeof guestTimezone === 'string' ? guestTimezone : guestTimezone?.value;
    const timezonesOffset =
      dayjs().tz(calendarTimezone).utcOffset() -
      dayjs().tz(guestTimezone).utcOffset();

    if (timezonesOffset > 0)
      return dayjs(date).add(timezonesOffset, 'minutes').tz(GMT0);
    else if (timezonesOffset < 0)
      return dayjs(date)
        .subtract(-1 * timezonesOffset, 'minutes')
        .tz(GMT0);
    else return dayjs(date).tz(GMT0);
  };

  const dateToGuestTimezone = (date: Date) => {
    guestTimezone =
      typeof guestTimezone === 'string' ? guestTimezone : guestTimezone?.value;
    const timezonesOffset =
      dayjs().tz(guestTimezone).utcOffset() -
      dayjs().tz(calendarTimezone).utcOffset();
    const format = 'h:mm A';
    if (timezonesOffset > 0)
      return dayjs(date)
        .add(timezonesOffset, 'minutes')
        .tz(GMT0)
        .format(format);
    else if (timezonesOffset < 0)
      return dayjs(date)
        .subtract(-1 * timezonesOffset, 'minutes')
        .tz(GMT0)
        .format(format);
    else return dayjs(date).tz(GMT0).format(format);
  };

  const onPeriodStartDateChanged = (month: number) => {
    onSelectedMonthChanged &&
      onSelectedMonthChanged(dayjs().month(month).startOf('month'), month);
  };

  const onSelectedDate = (date: Dayjs) => {
    onSelectedDateChanged && onSelectedDateChanged(date);
  };
  const onBookingConfirm = async (data: GuestInfos) => {
    if (selectedDate && currentTime) {
      const startTime = timeToDateWithTimezone(
        selectedDate,
        currentTime.startTime,
        true,
      ).format();
      const computedEndTime = dayjs(currentTime.startTime, 'HH:mm')
        .add(calendar.durationInMinutes, 'minute')
        .format('HH:mm');
      const endTime = timeToDateWithTimezone(
        selectedDate,
        computedEndTime,
        true,
      ).format();
      if (appointmentToUpdate && appointmentToUpdate.guestEmail) {
        if (rescheduleAppointment) {
          return rescheduleAppointment(appointmentToUpdate.id, {
            startTime,
            endTime,
            guestEmail: data.guestEmail,
            guestName: data.guestName,
            guestTimezone:
              typeof guestTimezone === 'string'
                ? guestTimezone
                : guestTimezone?.value,
            location: data.location ?? '',
            guests: data.guests,
            rescheduleReason: data.rescheduleReason,
          });
        }
      } else {
        if (bookAppointment) {
          delete data.rescheduleReason;
          return bookAppointment(calendar.id ?? '', {
            startTime,
            endTime,
            guestEmail: data.guestEmail,
            guestName: data.guestName,
            guestTimezone:
              typeof guestTimezone === 'string'
                ? guestTimezone
                : guestTimezone?.value,
            location: data.location ?? '',
            additionalInfos: data.additionalInfos,
            guests: data.guests,
          });
        }
      }
    }
  };

  const formatLocation = (rawLocation: any) => {
    switch (rawLocation.type) {
      case 'google_calendar':
      case 'zoom_video':
        return t('appointmentWidget.videoConfLocation');
      case 'phone':
        return t('appointmentWidget.phoneLocation');
      case 'inPerson':
        return (
          rawLocation.description || t('appointmentWidget.inPersonLocation')
        );
      case 'phoneInbound':
        return (
          rawLocation.description || t('appointmentWidget.phoneInboundLocation')
        );
      default:
        return rawLocation.description || rawLocation.type;
    }
  };

  const createDate = (date: string, time: string, endDay?: boolean): Date => {
    const year = parseInt(date.slice(0, 4));
    const month = parseInt(date.slice(5, 7)) - 1;
    const day = parseInt(date.slice(8, 10));
    const hours = parseInt(time.slice(0, 2));
    const minutes = parseInt(time.slice(3, 5));
    if (hours === minutes && hours === 0 && endDay)
      return new Date(Date.UTC(year, month, day, 23, 59) + 60 * 1000);
    return new Date(Date.UTC(year, month, day, hours, minutes));
  };
  const initializeTimeslotsForCurrentDate = useCallback(() => {
    const timeslotsForCurrentDate = timeSlots?.find(
      (slot) => slot.dateOfDay === selectedDate?.format('YYYY-MM-DD'),
    );
    if (timeslotsForCurrentDate)
      setAvailableTimeslotsForSelectedDate(timeslotsForCurrentDate);
  }, [selectedDate, timeSlots]);

  useEffect(() => {
    initializeTimeslotsForCurrentDate();
  }, [initializeTimeslotsForCurrentDate]);

  const getLocalisation = (event: any) => {
    setLocation(event.target.value);
  };

  return (
    <div className="flex flex-col-reverse py-4 md:flex-col md:py-6">
      <main
        className={classNames(
          hideInfosColumn ? '' : 'w-full',
          'relative max-w-6xl mx-auto bg-white border rounded-md shadow-sm',
        )}
      >
        {!shouldHideCalendarImage && calendar?.imageUrl && (
          <div className="h-52">
            <img
              className="object-fill w-full h-full md:object-cover rounded-tl-md rounded-tr-md"
              src={calendar.imageUrl}
              alt=""
            ></img>
          </div>
        )}
        {hideInfosColumn && (
          <h1 className="my-3 text-lg font-semibold text-center text-gray-900 truncate">
            {`${calendar.group.name} / ${calendar.name}`}
          </h1>
        )}
        <div
          className={classNames(
            hideInfosColumn
              ? ''
              : selectedDate
              ? 'divide-y lg:divide-y-0 lg:divide-x'
              : 'md:divide-x',
            selectedDate ? 'lg:flex-row' : 'md:flex-row',
            'flex flex-col justify-center flex-grow w-full py-2 sm:px-3',
          )}
        >
          {/* Appointment infos column */}
          {!hideInfosColumn && (
            <div
              className={classNames(
                selectedDate ? 'flex-1' : 'flex-1',
                !shouldHideCalendarImage && calendar?.imageUrl
                  ? '-mt-20'
                  : 'relative',
                'flex flex-col px-2 py-2 divide-y-0 w-full ',
              )}
            >
              {isGoBack && !previewMode && (
                <button
                  type="button"
                  className="absolute inline-block mb-2 uppercase transition duration-150 ease-in-out bg-white rounded-full leading-norma top-2 left-2 w-9 h-9"
                  onClick={goBack}
                >
                  <ArrowNarrowLeftIcon className="w-6 h-6 text-blue-600 animate-bounce-right" />
                </button>
              )}
              {/* Langage dropdown */}
              {!hideLangageSelection && (
                <Menu as="div" className="absolute top-2 right-2">
                  <div>
                    <Menu.Button className="flex items-center max-w-xs text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100 dark:focus:ring-gray-700 ring-offset-transparent">
                      <span className="sr-only">Open langage menu</span>
                      <GlobeAltIcon className="w-5 h-5 text-gray-500" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {[
                          { label: t('appSideBarLayout.langEN'), value: 'en' },
                          { label: t('appSideBarLayout.langFR'), value: 'fr' },
                        ].map((lang: { label: string; value: string }) => (
                          <Menu.Item key={lang.value}>
                            {({ active }) => (
                              <button
                                key={lang.value}
                                type="button"
                                onClick={(evt) =>
                                  i18n.changeLanguage(lang.value)
                                }
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex justify-between w-full px-4 py-2 text-sm text-gray-700',
                                )}
                              >
                                <span>{lang.label}</span>
                                {i18n.language === lang.value && (
                                  <span
                                    className={classNames(
                                      active
                                        ? 'text-indigo-600'
                                        : 'text-indigo-600',
                                      'flex items-center',
                                    )}
                                  >
                                    <CheckIcon
                                      className={classNames('w-5 h-5')}
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
              {/* Group Picture */}
              {calendar.group.forceBranding && (
                <div className="flex justify-center my-1 md:my-2">
                  <a href={`/${calendar.group.slug}`}>
                    <img
                      className={classNames(
                        !shouldHideCalendarImage && calendar.imageUrl
                          ? 'border'
                          : '',
                        'w-24 h-24 rounded-full',
                      )}
                      src={calendar.group.profilePictureUrl}
                      alt={calendar.group.name}
                    />
                  </a>
                </div>
              )}
              <div
                className={classNames(
                  hideLangageSelection ? 'mt-10' : '',
                  'flex flex-col py-2 mt-10 space-y-2',
                )}
              >
                {/* User avatar */}
                {calendar.showHostAvatar &&
                  calendar.calendarAssignments.length === 1 &&
                  calendar.calendarAssignments[0].user && (
                    <div
                      className={classNames(
                        calendar.group.forceBranding ? 'flex-row' : 'flex-col',
                        'flex items-center',
                      )}
                    >
                      <div>
                        <img
                          className={classNames(
                            calendar.group.forceBranding
                              ? 'w-14 h-14'
                              : 'w-24 h-24',
                            'inline-block rounded-full',
                          )}
                          src={
                            calendar.calendarAssignments[0].user
                              .profilePictureUrl
                          }
                          alt=""
                        />
                      </div>
                      <div className="my-1 ml-3">
                        <p className="text-base font-semibold text-gray-500 group-hover:text-gray-900">
                          {calendar.calendarAssignments[0].user.name}
                        </p>
                        {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p> */}
                      </div>
                    </div>
                  )}
                {calendar.showHostAvatar &&
                  calendar.calendarAssignments.length > 1 &&
                  calendar.calendarAssignments.length < 7 && (
                    <div
                      className={classNames(
                        calendar.group.forceBranding
                          ? 'flex-row'
                          : 'flex-col mt-6',
                        'flex items-center',
                      )}
                    >
                      <div className="flex -space-x-2 overflow-hidden">
                        {calendar.calendarAssignments
                          .filter((member) => member.active)
                          .map((member, memberIdx) => (
                            <img
                              key={memberIdx}
                              className={classNames(
                                calendar.group.forceBranding
                                  ? 'w-10 h-10'
                                  : 'w-14 h-14',
                                'inline-block rounded-full ring-2 ring-white',
                              )}
                              src={member.user?.profilePictureUrl}
                              alt=""
                            />
                          ))}
                      </div>
                    </div>
                  )}
                <h1 className="text-lg font-semibold text-gray-900 ">
                  {calendar.name}
                </h1>
                <div className="flex justify-start ">
                  <ClockIcon className="w-6 h-6 mr-4 text-gray-400" />
                  <span className="text-base font-medium text-gray-600">{`${calendar.durationInMinutes} mins`}</span>
                </div>
                {isAllowedToCollectPayment && calendar.price > 0 && (
                  <div className="flex justify-start ">
                    <CreditCardIcon className="w-6 h-6 mr-4 text-gray-400" />
                    <span className="text-base font-medium text-gray-600">
                      <IntlProvider locale="en">
                        <FormattedNumber
                          value={calendar.price / 100.0}
                          // eslint-disable-next-line react/style-prop-object
                          style="currency"
                          currency={calendar.currency.toUpperCase()}
                        />
                      </IntlProvider>
                    </span>
                  </div>
                )}
                {location && (
                  <div className="flex justify-start ">
                    {['google_calendar', 'zoom_video'].includes(location) ? (
                      <VideoCameraIcon className="w-6 h-6 mr-4 text-gray-400" />
                    ) : location === 'phone' ? (
                      <PhoneIncomingIcon className="w-6 h-6 mr-4 text-gray-400" />
                    ) : location === 'phoneInbound' ? (
                      <PhoneOutgoingIcon className="w-6 h-6 mr-4 text-gray-400" />
                    ) : (
                      <LocationMarkerIcon className="w-6 h-6 mr-4 text-gray-400" />
                    )}
                    <span className="text-sm font-medium text-gray-600">
                      {`${formatLocation(
                        calendar.locations.find((loc) => loc.type === location),
                      )}`}
                    </span>
                  </div>
                )}
                {selectedDate && currentTime && (
                  <>
                    <div className="flex justify-start ">
                      <CalendarIconSolid className="w-6 h-6 mr-4 text-green-400" />
                      <div className="flex space-x-2">
                        <span className="text-base font-medium text-green-600">
                          {`${timeToDateWithTimezone(
                            selectedDate,
                            currentTime?.startTime,
                          ).format('HH:mm')}-${timeToDateWithTimezone(
                            selectedDate,
                            dayjs(currentTime.startTime, 'HH:mm')
                              .add(calendar.durationInMinutes, 'minute')
                              .format('HH:mm'),
                          ).format('HH:mm')}`}
                        </span>

                        <div className="text-base text-green-500 font-base">
                          {dayjs(
                            createDate(
                              selectedDate.format('YYYY-MM-DD') ?? '',
                              currentTime?.startTime ?? '',
                            ),
                          )
                            .locale(i18n.language)
                            .format('dddd DD MMMM YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-start ">
                      <GlobeIcon className="w-6 h-6 mr-4 text-gray-400" />
                      <div className="flex space-x-2">
                        <span className="text-base font-medium text-gray-600">
                          {guestTimezone}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {/* When the appointment is beeing rescheduled */}
                {appointmentToUpdate && (
                  <div className="flex flex-col py-2 space-y-1">
                    <h3 className="text-sm font-semibold text-gray-700 ">
                      {t('appointmentWidget.formTime')}
                    </h3>
                    <span className="my-1 text-xs font-normal text-red-400">
                      {t('appointmentWidget.cancelThisPreviousAppointment')}
                    </span>
                    <div className="flex justify-start space-x-4">
                      <CalendarIcon className="w-8 h-8 text-red-500" />
                      <div className="flex flex-col">
                        <span className="text-base font-medium text-red-600 line-through">
                          {`${dateToGuestTimezone(
                            appointmentToUpdate.startTime,
                          )}-${dateToGuestTimezone(
                            appointmentToUpdate.endTime,
                          )}`}
                        </span>
                        <div className="text-base font-medium text-red-500 line-through ">
                          {dayjs(dayjs(appointmentToUpdate.startTime))
                            .locale(i18n.language)
                            .format('dddd DD MMMM YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="py-2 overflow-y-auto text-sm font-normal text-gray-700 max-h-96 no-tailwind"
                  dangerouslySetInnerHTML={{ __html: calendar.description }}
                ></div>
              </div>
            </div>
          )}
          {/* Time spot container */}
          <div
            className={classNames(
              selectedDate ? 'flex-2' : 'flex-1',
              'px-2 flex flex-col',
              !shouldHideCalendarImage && calendar?.imageUrl ? 'mt-3' : '',
              currentTime ? 'hidden' : '',
            )}
          >
            <div className="flex flex-col h-full sm:space-x-2 sm:flex-row">
              <div
                className={classNames(
                  selectedDate ? '' : '',
                  'flex-grow lg:max-w-lg py-4 px-3 md:px-0',
                )}
              >
                <DatePicker
                  weekStart="Sunday"
                  date={selectedDate}
                  isLoadingSlots={isLoadingSlots}
                  periodType="range"
                  isDayDisabled={isDayDisabled}
                  onDatePicked={onSelectedDate}
                  organizerTimeZone={dayjs.tz.guess()}
                  onPeriodStartDateChanged={onPeriodStartDateChanged}
                  periodStartDate={initialPeriodStart}
                  periodEndDate={dayjs(initialPeriodStart)
                    .endOf('year')
                    .format()}
                />
                <div className="mt-4">
                  <TimeZone
                    tz={
                      typeof guestTimezone === 'string'
                        ? guestTimezone
                        : guestTimezone?.value
                    }
                    setTz={changeGuestTimezone}
                  />
                </div>
              </div>
              {/* Time picker */}
              <div
                className={classNames(
                  selectedDate ? '' : 'hidden',
                  'flex-grow-0 px-2 py-4 sm:w-1/3',
                )}
              >
                <p className="m-auto text-sm font-medium text-center text-gray-500 uppercase w-36">
                  {selectedDate && selectedDate.format('dddd DD MMM')}
                </p>
                {selectedDate &&
                  availableTimeslotsForSelectedDate.slots.length > 0 && (
                    <TimeSlotButton
                      timeSlots={availableTimeslotsForSelectedDate}
                      onSlotSelected={onTimeSlotSelected}
                      isDiagnostic={isDiagnosticMode}
                      showRemainingSpot={showRemainingSpot}
                    />
                  )}
              </div>
            </div>
            {showTroubleShotButton && onTroubleShotClicked && (
              <div className="flex justify-end w-full px-2">
                <button
                  className="flex px-2 py-2 text-sm leading-none text-gray-400 transition border border-gray-400 border-dashed rounded hover:border-gray-600 hover:text-gray-600"
                  onClick={() =>
                    onTroubleShotClicked(isDiagnosticMode ? false : true)
                  }
                >
                  {!isDiagnosticMode && (
                    <QuestionMarkCircleIcon className="w-4 h-4 mr-1" />
                  )}
                  {t(
                    isDiagnosticMode
                      ? 'appointmentWidget.closeTroubleShot'
                      : 'appointmentWidget.troubleShotLabel',
                  )}
                </button>
              </div>
            )}
          </div>
          {/* Guest infos */}
          <Transition
            as={Fragment}
            show={selectedDate !== undefined && currentTime !== undefined}
            enter="transform transition-transform transition-opacity duration-[200ms]"
            enterFrom="opacity-20 translate-x-1/4"
            enterTo="opacity-100 translate-x-0"
            leave="transform transition-transform transition-opacity duration-[200ms]"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-20 translate-x-1/4"
          >
            <div
              className={classNames(
                'flex-1 px-2 py-2',
                !shouldHideCalendarImage && calendar?.imageUrl ? 'mt-3' : '',
                currentTime ? '' : 'hidden',
              )}
            >
              {hideInfosColumn && (
                <div className="mb-4 space-y-2">
                  <div className="flex justify-start ">
                    <ClockIcon className="w-6 h-6 mr-4 text-gray-400" />
                    <span className="text-base font-medium text-gray-600">{`${calendar.durationInMinutes} mins`}</span>
                  </div>
                  <div className="flex justify-start ">
                    <CalendarIconSolid className="w-6 h-6 mr-4 text-green-400" />
                    {selectedDate && currentTime && (
                      <div className="flex space-x-2">
                        <span className="text-base font-medium text-green-600">
                          {`${timeToDateWithTimezone(
                            selectedDate,
                            currentTime?.startTime,
                          ).format('HH:mm')}-${timeToDateWithTimezone(
                            selectedDate,
                            dayjs(currentTime.startTime, 'HH:mm')
                              .add(calendar.durationInMinutes, 'minute')
                              .format('HH:mm'),
                            false,
                          ).format('HH:mm')}`}
                        </span>
                        <Moment
                          format="dddd LL"
                          className="text-base text-green-500 font-base"
                          locale={i18n.language}
                        >
                          {dayjs(
                            createDate(
                              selectedDate.format('YYYY-MM-DD') ?? '',
                              currentTime?.startTime ?? '',
                            ),
                          ).toISOString()}
                        </Moment>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <h5
                className={classNames(
                  'text-base text-center font-semibold text-gray-700',
                )}
              >
                {t('appointmentWidget.enterYourInformation')}
              </h5>
              <AppointmentGuestInfos
                defaultValue={
                  appointmentToUpdate && {
                    location: appointmentToUpdate.location
                      ? appointmentToUpdate.location.split(':')[0]
                      : calendarLocations?.length === 1
                      ? calendarLocations[0]
                      : undefined,
                    guestEmail: appointmentToUpdate.guestEmail,
                    locationDescription:
                      appointmentToUpdate.location?.split(':')[1],
                    guestName: appointmentToUpdate.guestName,
                    additionalInfos: appointmentToUpdate.additionalInfos as any,
                    guests:
                      appointmentToUpdate.attendees
                        ?.slice(1)
                        .map((attendee) => attendee.email) || [],
                  }
                }
                onCancel={onCancelFromGuestInfo}
                previewMode={previewMode}
                onGuestInfosSubmit={onBookingConfirm}
                locationValue={getLocalisation}
                calendarLocations={calendarLocations}
                calendarCustomInputs={calendarCustomInputs}
                disableGuests={calendar.disableGuests || false}
                onFileUpload={(file) =>
                  onFileUpload ? onFileUpload(file) : ''
                }
                appointmentToUpdate={appointmentToUpdate ? appointmentToUpdate : undefined}
              />
            </div>
          </Transition>
        </div>
      </main>
      {showRibbon && (
        <div className="flex justify-center mb-1 md:mt-1">
          <a
            href="https://yalendar.com?utm_source=embed&utm_medium=powered-by-button"
            target="_blank"
            className="flex justify-center space-x-1"
            rel="noreferrer"
          >
            <span className="text-base font-normal text-gray-400">
              {t('appointmentWidget.poweredBy')}
            </span>
            <span className="font-semibold text-indigo-400 transition-colors duration-1000 text-md hover:text-indigo-900">
              Yalendar
            </span>
          </a>
        </div>
      )}
    </div>
  );
}

export default React.memo(AppointmentWidget);
